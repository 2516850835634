<template>
  <div class="number-list" :class="themeColorClass">
    <UiHeader
      v-if="header"
      class="number-list__heading"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
      :theme-color="themeColor"
    />

    <ul v-if="itemList.length" class="number-list__ul">
      <li v-for="item in itemList" :key="item.id" class="number-list__item">
        <div class="number-list__title-wrap row v-center">
          <div class="number-list__number no-shrink row v-center" />

          <T3HtmlParser
            v-if="item.name"
            :tag="item.text ? 'h3' : 'div'"
            class="number-list__title grow"
            :content="item.name"
          />
        </div>

        <T3HtmlParser
          v-if="item.text"
          class="number-list__text"
          :content="item.text"
        />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type { UiCeProductBoxesProps } from '~/types'
import { UiHeader } from '~ui/components'
import useTheming from '~ui/composables/useTheming'

const props = defineProps<UiCeProductBoxesProps>()
const themeColorClass = useTheming(props.themeColor)
</script>

<style lang="scss">
.number-list {
  &__heading {
    margin-bottom: rem(90px);
  }

  &__ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    counter-reset: number-list-item-number;

    @include media-query(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: rem(48px);
    }
  }

  &__item {
    text-align: left;
    counter-increment: number-list-item-number;
    padding-bottom: em(35px);
    margin: 0 auto;

    @include media-query(sm) {
      padding-bottom: 0;
    }
  }

  &__number {
    height: rem(51px);
    border-right: 4px solid var(--theme__theme-color, color(primary));
    line-height: 1;
    padding-right: rem(11px);

    &::before {
      content: counter(number-list-item-number, decimal-leading-zero);
      font-size: rem(50px);
      font-weight: 700;
      color: color(wild-sand);
    }
  }

  &__title {
    font-size: rem(24px);
    line-height: 1.2;
    font-weight: 700;
    padding: 0 em(14px);
  }

  &__text {
    padding-top: em(14px);
    line-height: 1.3;
  }
}
</style>
